.wrapper ol {
  list-style-type: decimal;
  padding: 0 16px;
}

.wrapper ul {
  list-style-type: disc;
  padding: 0 16px;
}

.wrapper ol li {
  padding: 0 3px;
}

.wrapper div p {
  min-height: 16px;
}

.wrapper div p br, .wrapper div br  {
  content: " ";
  display: block;
  min-height: 16px;
}

.wrapper div h2, .wrapper div h1, .wrapper div h3, .wrapper div h4, .wrapper div h5, .wrapper div h6, .wrapper div p span strong, .wrapper div p strong {
  font-family: var(--font-family-7);
}

.wrapper div h1 {
font-size: 22px;
}

.wrapper div h2 {
  font-size: 20px;
}

.wrapper div h3{
  font-size: 18px;
}

.wrapper div h4{
  font-size: 16px;
}

.wrapper div h5{
  font-size: 14px;
}

.wrapper div h6{
  font-size: 12px;
}