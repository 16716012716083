@mixin flexCenter {
	display: flex;
	justify-content: center;
	align-items: center;
}

.loading {
	width: 100%;
	height: 100vh;
	@include flexCenter;
}

.container {
	position: relative;
	z-index: 1;
	width: 100%;
	// height: fit-content;
	// overflow: hidden;
	// background-color: #f7f7f7;
}

.snk {
	position: relative;
	z-index: 1;
	// padding: 56px 16px 40px 16px;
	font-size: 12px;

	// @media (min-width: 768px) {
	// 	padding: 76px 0 80px 0;
	// }

	&_bg {
		position: absolute;
		z-index: 1;
		width: 375px;
		height: 4414px;
		top: 0;
		left: 50%;
		transform: translate(-50%, 0);

		@media (min-width: 768px) {
			width: 768px;
			height: 1089px;
		}

		@media (min-width: 1440px) {
			width: 1505px;
			height: 3088px;
		}
	}

	&_back {
		position: absolute;
		z-index: 2;
		top: 16px;
		left: 16px;
		font-size: 12px;
		// @include font(
		// 	$font-satoshi-bold,
		// 	12px,
		// 	$color-neutral-70,
		// 	700,
		// 	16px,
		// 	normal
		// );

		@media (min-width: 768px) {
			font-size: 24px;
			// @include font(
			// 	$font-satoshi-bold,
			// 	16px,
			// 	$color-neutral-70,
			// 	700,
			// 	24px,
			// 	normal
			// );
		}

		// @include media('>1152px') {
		// 	width: 1152px;
		// 	top: 20px;
		// 	left: 50%;
		// 	transform: translate(-50%, 0);
		// }

		a {
			height: 24px;
			width: fit-content;
			display: flex;
			align-items: center;
			gap: 4px;
		}

		svg {
			@media (min-width: 768px) {
				width: 24px;
				height: 24px;
			}
		}
	}

	&_box {
		background: #ffffff;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
		border-radius: 8px;
		padding: 24px 16px;
		max-width: 343px;
		width: 100%;
		margin: 0 auto;

		@media (min-width: 768px) {
			max-width: 762px;
			padding: 40px 80px;
		}
	}

	&_title {
		font-size: 24px;
		// @include font($font-clash-display-bold,
		// 	24px,
		// 	$color-neutral-70,
		// 	700,
		// 	32px,
		// 	normal);
		text-align: center;
		margin: 0 0 16px 0;

		@media (min-width: 768px) {
			font-size: 24px;
			// @include font($font-clash-display-bold,
			// 	28px,
			// 	$color-neutral-70,
			// 	700,
			// 	40px,
			// 	normal);
			margin: 0 0 32px 0;
		}
	}

	&_sub {
		// @include font($font-satoshi-medium, 14px, #646464, 500, 23px, normal);

		@media (min-width: 768px) {
			// @include font($font-satoshi-medium, 16px, #646464, 500, 24px, normal);
		}
	}

	&_desc {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		margin: 16px 0 24px 0;
		gap: 16px;

		@media (min-width: 768px) {
			gap: 32px;
			margin: 32px 0;
		}
	}

	&_content {
		// @include font($font-satoshi-black,
		// 	14px,
		// 	$color-neutral-70,
		// 	900,
		// 	22px,
		// 	normal);
		display: flex;
		margin: 0 0 8px 0;

		@media (min-width: 768px) {
			// @include font($font-satoshi-black,
			// 	20px,
			// 	$color-neutral-70,
			// 	900,
			// 	28px,
			// 	normal);
		}
	}

	&_item {
		margin: 8px 0 0 0;

		@media (min-width: 768px) {
			margin: 16px 0 0 0;
		}
	}

	ol {
		padding-left: 10px;
	}

	li {
		// @include font($font-satoshi-medium, 14px, #646464, 500, 23px, normal);
		margin-left: 5px;

		@media (min-width: 768px) {
			// @include font($font-satoshi-medium, 16px, #646464, 500, 24px, normal);
		}
	}

	&_tab {
		margin: 0 0 0 16px;
		list-style-type: disc;

		@media (min-width: 768px) {
			margin: 0 0 0 25px;
		}
	}

	&_tab2 {
		margin: 0 0 0 40px;
		list-style-type: disc;

		@media (min-width: 768px) {
			margin: 0 0 0 51px;
		}
	}

	&_tab3 {
		margin: 0 0 0 60px;
		list-style-type: disc;

		@media (min-width: 768px) {
			margin: 0 0 0 76px;
		}
	}

	&_ctaBtn {
		margin-bottom: 20px;

		@media (min-width: 768px) {
			width: 273px;
			margin: 0 auto;
		}
	}

	&_red {
		color: red;
	}
}